<template>
  <footer class="page-footer text-center">
    <img class="thumbnail text-center pb-2" width="150" src="https://ikaros.studio/wp-content/uploads/2021/01/BLACK-768x112.png" />
    <p>
      <a href="https://www.ikaros.studio"><strong>IKAROS STUDIO</strong> Berlin</a>
      <br>
      <a href="mailto:contact@ikaros.online">contact@ikaros.online</a>
      <br>
        <router-link to="/imprint">imprint</router-link> |  <router-link to="/imprint">data privacy</router-link>
    </p>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>

</style>