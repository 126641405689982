import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import Footer from './components/Footer.vue';
import Recaptcha from './components/Recaptcha.vue';
import axios from 'axios'
import anime from 'animejs/lib/anime.es.js';
import { firestorePlugin } from 'vuefire';


const lang = localStorage.getItem('lang') || 'de';
axios.defaults.baseURL = 'http://localhost:8080/';
axios.defaults.headers['Accept-Language'] = lang;

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin);
Vue.use(firestorePlugin);
Vue.use(anime);
Vue.component('Footer', Footer);
Vue.component('Recaptcha', Recaptcha);


anime = require('animejs');

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


new Vue({
  components: {
  Footer,
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
