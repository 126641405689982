
<template>
  <div>
    <div class="about container">
      <div class="text-center justify-center align-center"></div>
      <b-row
        :id="question.id"
        v-for="(question, index) in questions"
        :key="index"
        class="align-items-center"
      >
        <b-col cols="12">
          <!-- Slide with blank fluid image to maintain slide aspect ratio -->
          <div id="answer" class="text-left overflow-auto">
            <h1 class="font-weight-bold text-left d-block">
              {{ question.content }}
            </h1>

            <!-- ANSWER LOOP -->
            <div class="d-flex flex-row flex-nowrap overflow-auto my-4">
              <div
                v-for="answer in sortedAnswers"
                v-if="answer.question == question.content"
                :key="answer.id"
              >
                <b-card
                  :style="{
                    background:
                      colors[Math.floor(Math.random() * colors.length)],
                  }"
                  class="mr-4 p-2 colorcard border text-justify"
                >
                  <div class="colorcardcontent overflow-auto">
                    <h3 id="" class="">
                      {{ answer.content }}
                    </h3>
                  </div>
                  <hr />
                  <h4 class="w-100">
                    <strong>{{ answer.authorname }}</strong
                    >, {{ answer.authorage }}
                    <vue-moments-ago
                      prefix="answered"
                      suffix="ago"
                      :date="answer.date"
                      lang="en"
                    ></vue-moments-ago>
                  </h4>
                </b-card>
              </div>
              <div>
                <b-card class="ctacard p-2" v-if="answers.length !== 0">
                  <h3 class="pb-2">
                    The project evolves itself with its participants, there is
                    no set goal or rule besides answering all the questions in
                    your own way. Now it's your turn. Pick any question and
                    answer it. Make your thoughts mutual.
                  </h3>
                  <hr />
                  <b-button
                    v-b-modal="'modal' + question.id"
                    variant="outline-primary"
                    class="d-block font-weight-bold"
                    >Answer Now</b-button
                  >
                </b-card>
              </div>
            </div>
          </div>
          <b-button
            v-b-modal="'modal' + question.id"
            variant="outline-primary"
            class="d-block font-weight-bold"
            >Answer Now</b-button
          >
          <b-modal
            :id="'modal' + question.id"
            :title="question.content"
            hide-footer
          >
            <form @submit.prevent="postAnswer()" class="my-5 w-100">
              <p id="questionid" hidden>{{ question.content }}</p>
              <b-col cols="12">
                <textarea
                  v-model="answerSub.content"
                  id="answerinput"
                  placeholder="Your answer..."
                  contenteditable
                  required
                ></textarea>
              </b-col>
              <b-col cols="12">
                <p class="mb-0">Your Name</p>
                <input
                  v-model="answerSub.authorname"
                  class="form-control"
                  required
                />
                <p class="pt-4 mb-0">Your age</p>
                <b-form-select
                  v-model="answerSub.authorage"
                  value="select"
                  required
                >
                  <b-form-select-option v-for="n in 99" :value="n" :key="n">{{
                    n
                  }}</b-form-select-option>
                </b-form-select>
              </b-col>

              <b-col cols="12">
                <vue-recaptcha
                  class="pt-4"
                  :sitekey="sitekey"
                  required
                  @verify="onVerify()"
                  @expired="onExpired"
                  aria-required="true"
                  :loadRecaptchaScript="true"
                >
                </vue-recaptcha>
                <b-button
                  :disabled="validateRecaptcha !== true"
                  class="d-block mt-5"
                  block
                  type="submit"
                  variant="outline-primary"
                  >Submit your answer</b-button
                >
              </b-col>
            </form>
          </b-modal>
        </b-col>
        <!-- Question SUBMIT -->
      </b-row>
      <b-row id="questionsubmit" class="my-auto align-items-center minvh-100">
        <b-col cols="12" id="sumbitquestion">
          <h1 class="text-left mb-3">
            <strong>Submit your questions.</strong>
            <br /><br />
            All of the projects questions come from it's audience. Feel free to
            submit your question to be curated.
          </h1>
          <form
            @submit.prevent="postQuestion"
            class="my-5 w-100"
            name="contact"
            data-netlify="true"
            style=""
          >
            <input name="bot-field" value="" hidden />
            <textarea
              v-model="questionprop"
              id="answersubmit"
              placeholder="Your question..."
              required
            ></textarea>

            <b-button
              type="submit"
              variant="outline-primary"
              class="d-block font-weight-bold"
              >Submit your question</b-button
            >
          </form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>


<style scoped>
.fa-chevron-circle-right {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 3;
  color: #979797;
}

.colorcard {
  width: 50vw;
  background: linear-gradient(-45deg, #ffcfc0, #ffdd9d, #ffb68b, #ffd49c);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-radius: 0.45rem;
  border-style: none;
  color: #fff !important;
}

.colorcardcontent {
  height: 35vh;
}

.ctacard {
  width: 50vw;
}

.row {
  margin-top: 10vh !important;
  padding-bottom: 10vh !important;
}

@media only screen and (max-width: 600px) {
  .card,
  .ctacard {
    width: 79vw;
  }
  h1 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  .colorcardcontent {
    height: 55vh;
  }
  .vue-moments-ago {
    font-size: 1rem;
    position: relative !important;
    display: block !important;
    padding: 0 !important;
    margin-top: 8px;
    text-align: left !important;
  }
}

.vue-moments-ago {
  text-align: right;
  display: inline;
  font-size: 1.1rem;
  position: absolute;
  right: 0;
  padding-right: 28px;
  padding-top: 5px;
}
</style>

<script>
import axios from "axios";
import db from "./firebaseInit";
import VueRecaptcha from "vue-recaptcha";
import VueMomentsAgo from "vue-moments-ago";

export default {
  components: { VueRecaptcha, VueMomentsAgo },
  data() {
    return {
      validateRecaptcha: true,
      answerSub: {
        authorname: [],
        authorage: [],
        content: [],
        question: [],
      },
      questionprop: [],
      sitekey: "6LdNFk4aAAAAAJdTdIOQnbJ5wzOUCS5uF4YjRlpm",
      widgetId: 0,
      colors: [
        "#FBB9C5",
        "#FDD0B1",
        "#F9EFC7",
        "#C3EDBF",
        "#B8DFE6",
        "#C5BBDE",
      ],
      questions: [],
      answers: [],
      timestamp: "",
    };
  },
  created() {
    setInterval(this.getNow, 1000);
    db.collection("answers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = {
            id: doc.id,
            question: doc.data().question,
            authorname: doc.data().authorname,
            authorage: doc.data().authorage,
            content: doc.data().content,
            date: doc.data().date,
          };
          this.answers.push(data);
        });
      });
    db.collection("questions")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = {
            id: doc.id,
            content: doc.data().content,
          };
          this.questions.push(data);
        });
      });
  },
  computed: {
    sortedAnswers: function () {
      this.answers.sort((b, a) => {
        return new Date(a.date) - new Date(b.date);
      });
      return this.answers;
    },
  },
  methods: {
    isEmpty() {
      Object.keys(answer).length === 0;
    },
    onVerify() {
      this.$set(this.validateRecaptcha, true);
    },
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    postAnswer() {
      this.$set(
        this.answerSub,
        "question",
        document.getElementById("questionid").innerHTML
      );
      db.collection("answers")
        .add({
          authorname: this.answerSub.authorname,
          authorage: this.answerSub.authorage,
          content: this.answerSub.content,
          question: this.answerSub.question,
          date: this.timestamp,
        })
        .then(
          alert(
            "Thank you for answering! Your answer is now displayed below the question."
          )
        )
        .then((docRef) => this.$router.go())
        .catch((error) => console.log(err));
    },
    postQuestion() {
      db.collection("questionproposals")
        .add({
          content: this.questionprop,
        })
        .then(
          alert(
            "Thak you for for submitting a question! We are curating questions weekly."
          )
        )
        .then((docRef) => this.$router.go())
        .catch((error) => console.log(err));
    },
    activateReadMore() {
      this.readMoreActivated = true;
    },
    getNumbers: function (start, stop, step = 1) {
      return new Array(stop / step).fill(start).map((n, i) => (i + 1) * step);
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    questionMap() {
      return this.answers.filter(answer.id == question.id);
    },
    hideModal() {
      this.$bvModal.hide("modal" + question.id);
    },
    submit(response) {
      console.log(response);
    },
    // execute the recaptcha widget

    execute() {
      window.grecaptcha.execute(this.widgetId);
    },
    reset() {
      window.grecaptcha.reset(this.widgetId);
    },
    render() {
      if (window.grecaptcha) {
        this.widgetId = window.grecaptcha.render("g-recaptcha", {
          sitekey: this.sitekey,
          size: "invisible",
          // the callback executed when the user solve the recaptcha
          callback: (response) => {
            // emit an event called verify with the response as payload
            this.$emit("verify", response);
            // reset the recaptcha widget so you can execute it again
            this.reset();
          },
        });
      }
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    onSubmit(token) {
      document.getElementById("answersubmit").submit();
    },
  },
};
</script>
