<template>
  <VueRecaptcha
    :sitekey="this.sitekey"
    :loadRecaptchaScript="true"
    @verify="validate"
    required
  />
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import Validation from '@/services/recaptchaValidate';

export default {
      components: {VueRecaptcha},
      data () {
        return {
          sitekey: '6LdNFk4aAAAAAJdTdIOQnbJ5wzOUCS5uF4YjRlpm'
        }
      },
      methods: {
        validate (response) {
          Validation.validate({Response: response}).then(result => {
            this.$emit('validate', result.objectResult.success)
          }).catch(error => console.log(error))
        }
      }
}
</script>