<template>
  <div id="app">
    <div class="sharenav mr-3 mb-2 text-left">
        <a
        class="d-block"
        href="/#questionsubmit"
        target="__blank"
        ><i class="far fa-paper-plane mr-2"></i>Submit a question</a
      >
      <a class="d-block" href="https://paypal.me/ikarosstudio"><i class="far fa-heart mr-2"></i>Support us</a>
      <a
        class="d-block"
        href="https://www.instagram.com/ikaros.studio/"
        target="__blank"
        ><i class="fab fa-instagram mr-2"></i>Instagram</a
      >
      <a
        href="https://online.us2.list-manage.com/subscribe?u=9f59cbae0667d0f5cb5748c73&id=8f6db3d34b"
        target="__blank"
        ><i class="far fa-envelope mr-2"></i>Newsletter</a
      >
    </div>
    <div>
      <b-modal id="modal-1" title="BootstrapVue"> </b-modal>
    </div>
     <div id="nav">
      <router-link to="/">Questions</router-link> |
      <router-link to="/about">About</router-link>
          </div>
<transition>

   <router-view :key="$route.fullPath"></router-view>
   </transition>

    <Footer />
  </div>
</template>

<script>
  export default {
    data: function(){
      const lang = localStorage.getItem('lang') || 'en';
      return{
        lang: lang
      }
    },
    methods: {
      handleChange(event){
        localStorage.setItem('lang', event.target.value);
        window.location.reload();
      },
      goto(refName) {
    	var element = this.$els[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
      }
    }
  }
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://pro.fontawesome.com/releases/v5.10.0/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

body {
  font-family: "Cormorant Garamond", serif !important;
  font-weight: 300;
  color: #000;
}

input,
button,
select,
optgroup,
textarea,
label {
  font-family: "Cormorant Garamond", serif !important;
}
.answersubmit {
  font-family: "Cormorant Garamond", serif !important;
  font-size: 2.4rem;
}

.text-white{
  color: #fff !important}
  
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}

.carousel-slide {
  color: #000;
}

a {
  color: #000 !important;
}

a:hover {
  text-decoration: transparent !important;
}

.sharenav {
  position: fixed;
  float: right;
  overflow: hidden;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.cursor {
  position: relative;
}

.cursor i {
  position: absolute;
  width: 2px;
  height: 2.5rem;
  align-content: center;
  background-color: gray;
  left: 0;
  animation-name: blink;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  opacity: 1;
}

.cursor input:focus + i {
  display: none;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.btn-outline-primary {
  color: #000 !important;
  border-color: #000 !important;
  background-color: transparent !important;
  font-size: 1.5rem !important;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none !important;
}

#answerinput {
  font-family: "Cormorant Garamond", serif;
  min-height: 30vh;
  width: 100%;
  border: none;
  border-radius: 0; // For iOS
  // border-bottom: solid $width/150 rgba(white, .5);
  font-size: 1.5rem;
  transition: 0.3s ease;

  &:focus {
    outline: none;
    // border-bottom-color: $secondary-color;
    ~ label {
      top: 0;
    }

    ~ .bar:before {
      transform: translateX(0);
    }
  }

  // Stop Chrome's hideous pale yellow background on auto-fill
}

#answersubmit {
  font-family: "Cormorant Garamond", serif;
  width: 100%;
  border: none;
  border-radius: 0; // For iOS
  // border-bottom: solid $width/150 rgba(white, .5);
  font-size: 2.5rem;
  transition: 0.3s ease;

  &:focus {
    outline: none;
    // border-bottom-color: $secondary-color;
    ~ label {
      top: 0;
    }

    ~ .bar:before {
      transform: translateX(0);
    }
  }

  // Stop Chrome's hideous pale yellow background on auto-fill
}

#app {
  font-family: "Cormorant Garamond", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
}

.minvh-100 {
  min-height: 100vh;
}

.button,
.btn {
  border-radius: 0.45rem !important;
}

.colorcard {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
}
.mutualbutton {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-color: transparent !important;
  color: #fff !important;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.page-footer {
  color: #000;
  font-style: "Roboto";
}
.colorbutton {
   box-shadow:
      inset 50px 0 80px #ffd49c,
      inset -50px 0 300px #ffb68b,
      inset 50px 0 300px #f1a58d;
  color: white  !important;
  border:none  !important;

}
#nav {
  padding: 30px;
  font-size: 1.5rem;
  text-transform: uppercase;
  a {
    font-weight: bold;
    color: #8a8a8a !important;

    &.router-link-exact-active,
    .router-link-exact-hover {
      color: #000000 !important;
    }
  }
}

::-moz-selection { /* Code for Firefox */
  color:#fff;
  background: #000;
}

::selection {
  color:#fff;
  background: #000;
}

</style>

